<template>
  <v-container fluid fill-height class="pa-0 ma-0">
    <div class="login-backgroud-img"></div>
    <v-card
      v-if="!isComplete"
      id="login-card"
      class="elevation-1 login-card body-2"
    >
      <v-toolbar color="primary" dark flat height="80">
        <v-row justify="center">
          <div class="text-h6">パスワードの再設定</div>
        </v-row>
      </v-toolbar>
      <v-card-text class="mt-3 pt-2 pb-1">
        新しいパスワードを入力してください。
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
          <v-text-field
            v-model="password1"
            :rules="rules"
            type="password"
            maxlength="50"
            label="新しいパスワード"
          ></v-text-field>

          <v-text-field
            v-model="password2"
            :rules="rules"
            type="password"
            maxlength="50"
            label="新しいパスワード(確認用)"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <div class="login-btn px-4 pb-4">
        <v-btn
          block
          color="primary white--text"
          :loading="loading"
          :disabled="!valid || password1 != password2 || password1 == ''"
          @click="sendData"
          >送信</v-btn
        >
      </div>
    </v-card>
    <v-card v-else class="login-card">
      <DialogComplete text="変更が完了しました。" toTop="true" />
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

const DialogComplete = () => import("@/components/DialogComplete.vue");

export default {
  components: { DialogComplete },
  name: "Reset",
  data: () => ({
    loading: false,
    password1: "",
    password2: "",
    valid: true,
    sign: null,
    token: null,
    isComplete: false,
    rules: [
      (v) => !!v || "パスワードは必須項目です。",
      (v) => (v && v.length >= 8) || "8文字以上で入力してください。",
      (v) =>
        (v && v.length <= 32) || "パスワードは32文字以内で入力してください。",
    ],
    email: "",
  }),

  created() {
    this.token = this.$route.query.token;
    this.sign = this.$route.query.sign;
  },

  methods: {
    async sendData() {
      this.loading = true;

      try {
        const response = await axios.put(`${process.env.VUE_APP_URL}password/reset`, {
          sign: this.$route.query.sign,
          token: this.$route.query.token,
          password: this.password1
        }, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "Content-Type": "application/json"
          }
        });

        if (response.data.results === "complete") {
          this.isComplete = true;
          this.loading = false;
        } else {
          this.loading = false;
          this.isError = true;
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
      }
    },

  },
};
</script>